
.el-int{
      width: 250px;
      margin-left: 20px;
    }
.saixuan-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;

    .saixuan-ele {
        padding-left: 20px;
        & span {
            margin-right: 10px;
        }

    }
}
.activeClass {
  background: #2338e6;
  color: #ffffff;
}

.company-contain {
  margin-bottom: 20px;
  background-color: #ffffff;

  .timeBox {
    .radio-group {
      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #2338e6;
        border-color: #2338e6;
      }

      ::v-deep
        .el-radio-button__orig-radio:checked
        + .el-radio-button__inner:hover {
        color: #fff;
      }

      ::v-deep .el-radio-button__inner:hover {
        color: #2338e6;
      }
    }
  }

  .teachingGuidance {
    ::v-deep .el-button--primary {
      color: #fff;
      background-color: #495df1;
      border-color: #495df1;
    }

    ::v-deep .el-button--primary:focus,
    .el-button--primary:hover {
      background: #495df1;
      border-color: #495df1;
      color: #fff;
    }

    ::v-deep .el-button {
      padding: 8px 13px;
      border-radius: 2px;
    }
  }

  .operation {
    ::v-deep .el-button--primary {
      color: #fff;
      background-color: #495df1;
      border-color: #495df1;
    }

    ::v-deep .el-button--primary:focus,
    .el-button--primary:hover {
      background: #495df1;
      border-color: #495df1;
      color: #fff;
    }

    ::v-deep .el-button {
      padding: 8px 13px;
      border-radius: 2px;
    }
  }

  .divide {
    padding: 0 20px;
  }

  .filter-box {
    display: flex;
    position: relative;

    .box1 {
      padding: 10px 20px;
      display: flex;
      line-height: 40px;

      .filter-box1 {
        flex: 1;

        span {
          margin-top: 6px;
          line-height: 32px;
          display: inline-block;
          width: 66px;
          height: 32px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  ::v-deep .el-table td {
    border-bottom: 8px solid #ebeef5;
  }

  ::v-deep .el-table {
    display: flex;
    flex-direction: column;
    height: calc(100% - 114px);
  }

  ::v-deep .el-table--border::after,
  ::v-deep .el-table--group::after,
  ::v-deep .el-table::before {
    background-color: transparent;
  }

  ::v-deep .el-table--medium td,
  ::v-deep .el-table--medium th {
    padding: 10px 9px;
  }

  ::v-deep .el-table__footer-wrapper,
  ::v-deep .el-table__header-wrapper {
    overflow: initial;
  }

  ::v-deep .el-table__body-wrapper {
    overflow: auto;
  }

  .showing {
    display: flex;
    align-items: center;
    /*width: 30px;*/
    height: 30px;

    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      margin-right: 10px;
      border-radius: 50%;
    }

    .showing-name {
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
